/* src/styles/QuestionList.css */
.question-list {
    padding: 20px;
    padding-top: 80px;
  }
  
  .question-list h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .upload-form {
    margin-bottom: 20px;
  }
  
  .upload-form .form-group {
    margin-bottom: 10px;
  }

  .upload-form label {
    color: black; /* Set the label text color to black */
  }
  
  .upload-form input[type="file"] {
    margin-right: 10px;
  }
  
  .upload-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .upload-button:hover {
    background-color: #218838;
  }
  
  .question-list table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 200px;
  }
  
  .question-list th,
  .question-list td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .question-list th {
    background-color: #f4f4f4;
  }
  
  .question-list a {
    color: #007bff;
    text-decoration: none;
  }
  
  .question-list a:hover {
    text-decoration: underline;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }

  .view-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }

  .edit-button {
    background-color: #ffc107;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .edit-button:hover {
    background-color: #e0a800;
  }

  .add-button {
    background-color: #218838;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .add-button:hover {
    background-color: #218838;
  }
  
  .separator {
    margin: 0 5px;
    font-size: 16px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }

  .question-list .action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .question-list .action-buttons button {
    margin: 0 5px; /* Adjust margin for spacing between buttons */
  }
  

  .file-viewer {
    margin-top: 100px;
    padding-bottom: 200px;
  }
  
  .file-viewer h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .file-viewer table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .file-viewer td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .pagination-controls button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    margin: 0 5px;
  }
  
  .pagination-controls button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .pagination-controls span {
    margin: 0 10px;
  }
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.admin-login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  padding: 0 10px; /* Added padding for small screens */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.admin-login-page h2 {
  font-size: 2em; /* Adjusted font size */
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.login-form {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  color: #2575fc;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.login-button:hover {
  background-color: #2575fc;
  color: white;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .admin-login-page h2 {
    font-size: 1.8em;
  }

  .login-form {
    padding: 15px;
  }

  .login-button {
    font-size: 1em;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .admin-login-page h2 {
    font-size: 1.5em;
  }

  .login-form {
    padding: 10px;
  }

  .login-button {
    font-size: 0.9em;
    padding: 6px;
  }
}


/* src/styles/exam-dashboard.css */
/*.exam-dashboard {
  display: flex;
  height: 100vh;
  margin-top: 60px; 
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 10px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  top: 80px; 
  height: calc(100vh - 60px); 
  overflow-y: auto;
}

.question-status {
  display: flex;
  flex-wrap: wrap;
}

.question-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}

.question-circle.pending {
  background-color: #ccc;
}

.question-circle.attempted {
  background-color: #4caf50;
  color: white;
}

.question-circle.skipped {
  background-color: #f44336;
  color: white;
}

.exam-content {
  flex: 1;
  padding: 20px;
  padding-top: 80px;
  padding-right: 220px; 
  overflow-y: auto;
} 

.timer {
  font-size: 18px;
  margin-bottom: 20px;
}

.question-container {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
}

.question-container h3 {
  margin-bottom: 20px;
}

.options {
  margin: 20px 0;
}

.options label {
  display: block;
  margin-bottom: 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.navigation-buttons button:hover:not(:disabled) {
  background-color: #1976d2;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.submit-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}


@media (max-width: 768px) {
  .sidebar {
    width: 150px;
    padding: 5px;
  }

  .question-circle {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }

  .exam-content {
    padding-right: 160px;
  }

  .question-container {
    padding: 15px;
    margin-right: 10px;
  }

  .timer {
    font-size: 16px;
  }

  .navigation-buttons button {
    padding: 8px 15px;
  }

  .submit-button {
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100px;
    padding: 5px;
  }

  .question-circle {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
  }

  .exam-content {
    padding-right: 110px;
  }

  .question-container {
    padding: 10px;
    margin-right: 5px;
  }

  .timer {
    font-size: 14px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .navigation-buttons button {
    padding: 8px 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .submit-button {
    padding: 8px 10px;
    width: 100%;
  }
} */

.exam-dashboard {
  display: flex;
  flex-direction: column; /* Changed for responsive behavior */
  height: 100vh;
  margin-top: 60px;
}

.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 10px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  top: 80px;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.question-status {
  display: flex;
  flex-wrap: wrap;
}

.question-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
}

.question-circle.pending {
  background-color: #ccc;
}

.question-circle.attempted {
  background-color: #4caf50;
  color: white;
}

.question-circle.skipped {
  background-color: #f44336;
  color: white;
}

.exam-content {
  flex: 1;
  padding: 20px;
  padding-top: 80px;
  padding-right: 220px;
  overflow-y: auto;
  padding-bottom: 100px;
}

.timer {
  font-size: 18px;
  margin-bottom: 20px;
}

.question-container {
  position: relative;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  height: calc(100vh - 300px); /* Adjusted height */
  overflow-y: auto; /* Added for scrollability */
  padding-bottom: 100px;
}

.question-container h3 {
  margin-bottom: 20px;
}

.options {
  margin: 20px 0;
}

.options label {
  display: block;
  margin-bottom: 10px;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.navigation-buttons button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.navigation-buttons button:hover:not(:disabled) {
  background-color: #1976d2;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

/* Adding Slider for Questions */
.slider {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 10px;
  scroll-behavior: smooth;
}

.slider::-webkit-scrollbar {
  height: 6px;
}

.slider::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.slider-item {
  min-width: 300px;
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .exam-dashboard {
    flex-direction: column;
    margin-top: 0;
  }

  .sidebar {
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
  }

  .exam-content {
    padding-right: 0;
  }

  .question-container {
    height: calc(100vh - 250px); /* Adjusted for smaller screens */
  }
}

@media (max-width: 768px) {
  .sidebar {
    width: 150px;
    padding: 5px;
  }

  .question-circle {
    width: 25px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
  }

  .exam-content {
    padding-right: 160px;
  }

  .question-container {
    padding: 15px;
    margin-right: 10px;
  }

  .timer {
    font-size: 16px;
  }

  .navigation-buttons button {
    padding: 8px 15px;
  }

  .submit-button {
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100px;
    padding: 5px;
  }

  .question-circle {
    width: 20px;
    height: 20px;
    font-size: 10px;
    line-height: 20px;
  }

  .exam-content {
    padding-right: 110px;
  }

  .question-container {
    padding: 10px;
    margin-right: 5px;
  }

  .timer {
    font-size: 14px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  .navigation-buttons button {
    padding: 8px 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .submit-button {
    padding: 8px 10px;
    width: 100%;
  }
}




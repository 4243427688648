/* General styling for the page */
.edit-excel {
  margin: 0;
  padding: 20px;
  padding-bottom: 200px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Header styling */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Adjust as needed */
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

header h1 {
  margin: 0;
}

/* Main content area */
.main-content {
  margin-top: 60px; /* Adjust this based on the height of your header */
  padding: 20px;
  min-height: calc(100vh - 120px); /* Adjust based on header and footer height */
}

/* Footer styling */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px; /* Adjust as needed */
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Table styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

/* Input styling */
input[type="text"] {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

/* Button styling */
.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}

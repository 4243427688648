body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
}

.homepage h1 {
  font-size: 3em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.login-options {
  display: flex;
  gap: 20px;
}

.login-options a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2em;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid white;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.login-options a:hover {
  background-color: white;
  color: #2575fc;
}

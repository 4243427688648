.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay to highlight the modal */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff; /* White background for the modal */
  color: #000000; /* Black text color for visibility */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  text-align: left; /* Align text to the left */
}

h2 {
  margin-top: 0; /* Remove margin at the top of the header */
}

ol, ul {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  list-style-position: inside; /* Adjust list-style position for alignment */
}

ul {
  margin-left: 20px; /* Add left margin to nested lists */
}

button.close-button {
  padding: 10px 20px;
  background-color: #2575fc; /* Blue color for the button */
  color: #fff; /* White text color for the button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  display: inline-block;
  text-align: center;
}

button.close-button:hover {
  background-color: #6a11cb; /* Darker blue for hover effect */
}

/*body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.admin-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 60px; 
}

.admin-dashboard header {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 10px 0;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  top: 0;
  width: 100%;
  z-index: 1000;
}

.admin-dashboard .container {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.admin-dashboard .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.admin-dashboard .col {
  flex: 1;
  margin-right: 10px;
}

.admin-dashboard .card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-dashboard .card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-dashboard .card.active {
  border-color: #2980b9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-dashboard .card-body {
  display: flex;
  align-items: center;
}

.admin-dashboard .card-body i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.admin-dashboard .card-body span {
  font-size: 1rem;
}

.admin-dashboard .error-message {
  color: red;
  margin-top: 10px;
}

.admin-dashboard .login-button,
.admin-dashboard .admin-button {
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}

.admin-dashboard .login-button:hover,
.admin-dashboard .admin-button:hover {
  background-color: #2980b9;
}

.admin-dashboard .form-group {
  margin-bottom: 15px;
}

.admin-dashboard label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.admin-dashboard input[type="text"],
.admin-dashboard input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.logout-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #2575fc;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: #ffffff99;
  color: white;
}


@media (max-width: 768px) {
  .admin-dashboard .row {
    flex-direction: column;
  }
} */




body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.admin-dashboard {
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-top: 70px; /* Adjust this value based on your header height */
}

.admin-dashboard header {
  background-color: #2c3e50;
  color: #ffffff;
  padding: 60px 0;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-dashboard .container {
  display: flex;
  flex-direction: column;
}

.admin-dashboard .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.admin-dashboard .col {
  flex: 1;
  margin-right: 10px;
}

.admin-dashboard .card {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.admin-dashboard .card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-dashboard .card.active {
  border-color: #2980b9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-dashboard .card-body {
  display: flex;
  align-items: center;
}

.admin-dashboard .card-body i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.admin-dashboard .card-body span {
  font-size: 1rem;
}

.admin-dashboard .error-message {
  color: red;
  margin-top: 10px;
}

.admin-dashboard .login-button,
.admin-dashboard .admin-button {
  background-color: #3498db;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  transition: background-color 0.3s, color 0.3s;
}

.admin-dashboard .login-button:hover,
.admin-dashboard .admin-button:hover {
  background-color: #2980b9;
}

.admin-dashboard .form-group {
  margin-bottom: 15px;
}

.admin-dashboard label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.admin-dashboard input[type="text"],
.admin-dashboard input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.logout-button {
  background-color: #fff;
  border: none;
  border-radius: 5px;
  color: #2575fc;
  cursor: pointer;
  font-size: 1em;
  padding: 10px 20px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 20px; /* Space between heading and button */
}

.logout-button:hover {
  background-color: #ffffff99;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .admin-dashboard .row {
    flex-direction: column;
  }
}







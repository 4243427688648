/* src/styles/add-new-exam.css */

.add-new-exam-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  padding: 20px;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 60px; 
  margin-bottom: 60px;
}

.add-new-exam-form {
  width: 100%;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.add-new-exam-form h2 {
  font-size: 2em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: white;
}

.form-group input,
.form-group select,
.form-group input[type="date"],
.form-group input[type="datetime-local"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding does not exceed width */
}

.form-group input[type="file"] {
  padding: 5px;
}

.students-list {
  max-height: 200px;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
}

.student-item input[type="checkbox"] {
  width: auto; /* Adjust to the desired width */
  height: auto;
  margin-right: 10px; /* Space between checkbox and label text */
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-bottom: 20px;
}

.add-exam-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.add-exam-button:hover {
  background-color: #45a049;
}

/* Base styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Ensure the page takes up at least the full height of the viewport */
  padding: 60px 20px; /* Adjust padding to account for the header and footer */
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.signup-page h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.signup-form {
  width: 100%; /* Full width on smaller screens */
  max-width: 400px; /* Max width on larger screens */
  background-color: rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.signup-button {
  width: 100%;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #2575fc;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.signup-button:hover {
  background-color: #2575fc;
  color: white;
}

.error-message {
  color: red;
  margin-bottom: 15px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .signup-page h2 {
    font-size: 2em;
  }

  .signup-form {
    padding: 20px;
  }

  .signup-button {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .signup-page h2 {
    font-size: 1.5em;
  }

  .signup-form {
    padding: 15px;
  }

  .signup-button {
    font-size: 0.9em;
  }
}

/* Adjustments for larger screens to prevent content hiding under header/footer */
@media (min-width: 1024px) {
  .signup-page {
    padding: 80px 20px; /* Increase padding to ensure content is not hidden under header/footer */
  }

  .signup-form {
    padding: 40px;
  }
}

/* Main container for detailed result */
.detailed-result {
  font-family: Arial, sans-serif;
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f9f9f9;
}

/* Heading style for detailed result */
.detailed-result h2 {
  color: #333;
  margin-bottom: 20px;
}

/* Table styling */
.detailed-result table {
  width: 100%;
  border-collapse: collapse; /* Ensures single border between cells */
  margin-bottom: 20px;
  border: 2px solid black; /* Outer border for table */
}

/* Table header styling */
.detailed-result th {
  padding: 10px;
  background-color: #007bff; /* Header background color */
  color: white;
  font-weight: bold;
  border: 2px solid black; /* Border between header cells */
  text-align: left;
}

/* Table data cell styling */
.detailed-result td {
  padding: 10px;
  border: 2px solid black; /* Border between data cells */
  background-color: #fff;
  color: #333;
}

/* Alternating row colors (zebra striping) */
.detailed-result tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Result summary container */
.result-summary {
  background-color: #e9ecef;
  padding: 15px;
  border: 2px solid #007bff;
  border-radius: 5px;
}

/* Heading style for result summary */
.result-summary h4 {
  color: #007bff;
  margin-bottom: 10px;
}

/* Paragraph style for result summary */
.result-summary p {
  margin: 5px 0;
  font-weight: 500;
  color: #333;
}

/* Optional styling for different result types */
.correct-answer {
  color: green;
  font-weight: bold;
}

.incorrect-answer {
  color: red;
  font-weight: bold;
}

.skipped-answer {
  color: orange;
  font-weight: bold;
}

/*body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.student-dashboard {
  padding: 80px 20px 20px; 
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  min-height: 100vh;
  box-sizing: border-box; 
}

.student-dashboard h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.student-dashboard p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.student-dashboard button {
  padding: 10px 20px;
  color: white;
  background-color: #6a11cb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 20px;
}

.student-dashboard button:hover {
  background-color: #2575fc;
}

.exams-table {
  margin: 0 auto;
  width: 100%;
  max-width: 90%; 
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-x: auto; 
}

.exams-table th, .exams-table td {
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box; 
}

.exams-table th {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.exams-table td {
  background-color: rgba(255, 255, 255, 0.1);
}

.exams-table button {
  padding: 5px 10px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.exams-table button:hover {
  background-color: #2575fc;
  color: white;
}


@media (max-width: 768px) {
  .student-dashboard h1 {
    font-size: 2em;
  }

  .student-dashboard p {
    font-size: 1em;
  }

  .student-dashboard button {
    font-size: 1em;
    padding: 8px 15px;
  }

  .exams-table th, .exams-table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .student-dashboard h1 {
    font-size: 1.5em;
  }

  .student-dashboard p {
    font-size: 0.9em;
  }

  .student-dashboard button {
    font-size: 0.9em;
    padding: 6px 10px;
  }

  .exams-table th, .exams-table td {
    padding: 5px;
    font-size: 0.8em; 
  }
} */

/* src/styles/student-dashboard.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.student-dashboard {
  padding: 20px; /* Added top padding to avoid content being hidden by the fixed header */
  padding-top: 100px;
  padding-bottom: 400px;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  min-height: 100vh;
  box-sizing: border-box; 
}

.student-dashboard h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.student-dashboard p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.student-dashboard button {
  padding: 10px 20px;
  color: white;
  background-color: #6a11cb;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 20px;
}

.student-dashboard button:hover {
  background-color: #2575fc;
}

.table-container {
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

.exams-table {
  border-collapse: collapse;
  width: 100%; /* Ensure table width fits within container */
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.exams-table th, .exams-table td {
  border: 1px solid white;
  padding: 10px;
  text-align: center;
  box-sizing: border-box; 
}

.exams-table th {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.exams-table td {
  background-color: rgba(255, 255, 255, 0.1);
  word-break: break-word;
}

.exams-table button {
  padding: 5px 10px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.exams-table button:hover {
  background-color: #2575fc;
  color: white;
}

.student-dashboard .logout-button {
  margin-right: 20px; /* Adjust the margin as needed */
}

.student-dashboard .instructions-button {
  margin-left: 20px; /* Adjust the margin as needed */
}

/* Mobile styles */
@media (max-width: 768px) {
  .student-dashboard h1 {
    font-size: 2em;
  }

  .student-dashboard p {
    font-size: 1em;
  }

  .student-dashboard button {
    font-size: 1em;
    padding: 8px 15px;
  }

  .exams-table th, .exams-table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .student-dashboard h1 {
    font-size: 1.5em;
  }

  .student-dashboard p {
    font-size: 0.9em;
  }

  .student-dashboard button {
    font-size: 0.9em;
    padding: 6px 10px;
  }

  .exams-table th, .exams-table td {
    padding: 5px;
    font-size: 0.8em; 
  }
}


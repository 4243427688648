.exam-list-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  padding: 100px;
  box-sizing: border-box;
}

.exam-list-page h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.exam-table-container {
  width: 100%;
  overflow-x: auto; /* Ensure the table container is scrollable */
  margin: 0 auto;
}

.exam-table {
  width: 100%;
  max-width: 100%; /* Ensure table does not exceed container width */
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  table-layout: fixed; /* Fix the table layout */
}

.exam-table th,
.exam-table td {
  padding: 10px;
  border: 1px solid white;
  text-align: center;
  word-break: break-word; /* Allow words to break and wrap */
}

.exam-table th {
  background-color: rgba(0, 0, 0, 0.2);
  font-weight: bold;
}

.exam-table td {
  background-color: rgba(255, 255, 255, 0.1);
}

.exam-table button {
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.exam-table button:hover {
  background-color: #2575fc;
  color: white;
}

.delete-button {
  background-color: #e74c3c;
}

.delete-button:hover {
  background-color: #c0392b;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .exam-list-page h2 {
    font-size: 2em;
  }

  .exam-table th,
  .exam-table td {
    padding: 8px;
    font-size: 0.9em;
  }

  .exam-table button {
    font-size: 0.9em;
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .exam-list-page h2 {
    font-size: 1.5em;
  }

  .exam-table th,
  .exam-table td {
    padding: 5px;
    font-size: 0.8em;
  }

  .exam-table button {
    font-size: 0.8em;
    padding: 3px 6px;
  }
}



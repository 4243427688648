/* src/styles/add-subject.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
}

.add-subject-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: rgb(0, 0, 0);
  padding: 20px;
  padding-bottom: 80px;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
  margin-top: 60px; /* Adjust this value based on your header height */
  margin-bottom: 60px; /* Adjust this value based on your footer height */
}

.add-subject-page h2 {
  font-size: 2.5em;
  margin-top: 20px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.add-subject-form {
  width: 100%;
  max-width: 400px; /* Set max-width for better scaling */
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.add-subject-button {
  width: 100%;
  padding: 10px 20px;
  font-size: 1.2em;
  color: #2575fc;
  background-color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.add-subject-button:hover {
  background-color: #2575fc;
  color: white;
}

.sort-options {
  margin-bottom: 20px;
}

.sort-options label {
  margin-right: 10px;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.sort-options select {
  padding: 5px;
  border-radius: 5px;
  border: none;
}

.subjects-table {
  width: 100%;
  max-width: 80%; /* Set max-width for better scaling */
  margin: 0 auto;
  border-collapse: collapse;
  overflow-x: auto; /* Added overflow for small screens */
  box-sizing: border-box; /* Ensures padding doesn't affect width */
}

.subjects-table th,
.subjects-table td {
  padding: 10px;
  border: 1px solid white;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1); /* Apply background color to all cells */
}

.subjects-table th {
  background-color: rgba(255, 255, 255, 0.2);
}

.subjects-table button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.subjects-table button:hover {
  background-color: #c0392b;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .add-subject-page h2 {
    font-size: 2em;
  }

  .add-subject-form {
    padding: 15px;
  }

  .add-subject-button {
    font-size: 1em;
    padding: 8px;
  }

  .subjects-table th,
  .subjects-table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .add-subject-page h2 {
    font-size: 1.5em;
  }

  .add-subject-form {
    padding: 10px;
  }

  .add-subject-button {
    font-size: 0.9em;
    padding: 6px;
  }

  .subjects-table th,
  .subjects-table td {
    padding: 5px;
    font-size: 0.8em; /* Adjusted font size for small screens */
  }
}

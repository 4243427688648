.blocked-users {
    padding: 20px;
    margin-top: 80px; /* Added margin to ensure content is below the header */
  }
  
  .blocked-users h2 {
    margin-bottom: 20px;
  }
  
  .blocked-users-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .blocked-users-table th, .blocked-users-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .blocked-users-table th {
    background-color: #f4f4f4;
  }
  
  .blocked-users-table button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .blocked-users-table button:hover {
    background-color: #45a049;
  }
  
.student-list {
  width: 100%;
  padding: 100px 20px 80px 20px;
  box-sizing: border-box;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  margin-bottom: 80px;
}

.student-list table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure the table layout is fixed */
}

.student-list th,
.student-list td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  word-break: break-word; /* Allow words to break and wrap */
}

.student-list th {
  background-color: #8aef8f;
}

.delete-button {
  background-color: #ff3333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #cc0000;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .student-list th,
  .student-list td {
    padding: 8px;
    font-size: 0.9em;
  }

  .delete-button {
    font-size: 0.9em;
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .student-list th,
  .student-list td {
    padding: 5px;
    font-size: 0.8em;
  }

  .delete-button {
    font-size: 0.8em;
    padding: 3px 6px;
  }
}
/* Sort options styling */
.sort-options {
  margin-bottom: 20px;
}

.sort-options label {
  margin-right: 10px;
  font-weight: bold;
  color: black; /* Set label text color to black */
}

.sort-options select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: black; /* Ensure the text inside the select dropdown is also black */
}


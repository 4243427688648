/* src/styles/header.css */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background-color: #a4d1de; /* Adjust background color as needed */
    position: fixed;
    width: 100%;
    top: 0; /* Ensures the header is at the top */
    left: 0; /* Ensures the header covers the full width */
    z-index: 1000; /* Makes sure the header stays above other content */
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 50px; /* Adjust size as needed */
  }
  
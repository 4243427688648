  .results {
    padding: 100px 20px 80px 20px;
    box-sizing: border-box;
    overflow-x: auto; /* Enable horizontal scrolling if needed */
  }
  
  .results table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensure the table layout is fixed */
  }
  
  .results th,
  .results td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    word-break: break-word; /* Allow words to break and wrap */
  }
  
  .results th {
    background-color: #f2f2f2;
  }
  
  .delete-button {
    background-color: #f44336; /* Red */
    color: white;
    border: none;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #d32f2f; /* Darker Red */
  }
  
  /* Media Queries for Mobile Devices */
  @media (max-width: 768px) {
    .results th,
    .results td {
      padding: 6px;
      font-size: 0.9em;
    }
  
    .delete-button {
      font-size: 0.9em;
      padding: 5px 10px;
    }
  }
  
  @media (max-width: 480px) {
    .results th,
    .results td {
      padding: 4px;
      font-size: 0.8em;
    }
  
    .delete-button {
      font-size: 0.8em;
      padding: 4px 8px;
    }
  }
  
  .download-button {
    background-color: #4caf50; /* Green */
    color: white;
    border: none;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #45a049; /* Darker Green */
  }
.container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 1.2em;
    color: #333;
  }
  
/* src/styles/add-new-course.css */

.add-new-course-page {
  max-width: 600px;
  margin: 80px auto 80px auto; /* Adjust the top and bottom margin to avoid header and footer overlap */
  padding: 20px;
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.course-form .form-group {
  margin-bottom: 15px;
}

.course-form .form-group label {
  display: block;
  margin-bottom: 5px;
}

.course-form .form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.add-course-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
}

.courses-container .course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #000000;
  margin-bottom: 10px;
}

.courses-container .course h4 {
  color: #000; /* Ensure the color is set to a visible one like black */
  margin: 0;
}

.delete-course-button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

h2, h3 {
  color: #333; /* Change the color to a visible one */
  text-align: center; /* Center align the heading */
  margin-bottom: 20px; /* Add some space below the heading */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .add-new-course-page {
    margin: 100px auto 100px auto; /* Adjust top and bottom margin for smaller screens */
  }
}

@media (max-width: 480px) {
  .add-new-course-page {
    margin: 120px auto 120px auto; /* Adjust top and bottom margin for even smaller screens */
  }
}

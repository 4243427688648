/* src/styles/footer.css */
.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #000; /* Black background color */
    color: #ffffff;
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  
  .footer p {
    margin: 0;
  }
  